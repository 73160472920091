import React from "react";
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout";
import SEO from "../components/seo";
import SocialLinks from "../components/socialLinks";
import ArticleItem from "../components/articleItem";

function IndexPage({ data }) {
  const { posts, DativeLogo, RodrigoHeadshot } = data

  const latestPosts = posts && posts.edges.filter(({ node }) => new Date() >= new Date(node.fields.date))

  return (
    <Layout>
      <SEO
        title="Welcome"
        keywords="rodrigo, passos, rodrigopassos, javascript, craftcms"
      />

      <section className="max-w-4xl px-3 mx-auto mb-12 md:px-6 dark:text-gray-200">
        <h1 className="mb-6 text-4xl font-bold flex items-center">
          <GatsbyImage
            image={getImage(RodrigoHeadshot)}
            className="mr-6 rounded-full w-44 h-44"
            imgClassName="rounded-full"
            alt="Rodrigo Passos Headshot"
          />
          Hi, I’m Rodrigo. <span aria-hidden="true">👋</span>
        </h1>
        <div className="mb-12 text-xl leading-relaxed">
          I am a senior developer at <a className="font-bold text-black dark:text-white" href="https://hellodative.com" rel="noopener noreferrer" target="_blank"><GatsbyImage
            image={getImage(DativeLogo)}
            className="relative inline-block object-cover w-8 h-8 mx-2 -mb-2 rounded-none"
            imgClassName="rounded-none"
            alt="Dative Logo"
            width={8}
            height={8}
          />Dative, Inc</a>. In my spare time, I write code for fun, build side projects, volunteer, play guitar/piano, spend time with my daughter and write about what I’ve learned along the way. All from beautiful Cape Cod.
        </div>
        <SocialLinks />
      </section>

      <section className="flex flex-col max-w-4xl px-3 mx-auto md:px-6 md:justify-between md:flex-row dark:text-gray-200">
        <div className="md:w-1/3">
          <h2 className="mb-6 mt-0 text-3xl font-bold md:mb-0" id="projects">Writings</h2>
        </div>
        <div className="space-y-6 md:w-2/3">
          {latestPosts && latestPosts.map( ({ node }, index) => (
            <ArticleItem
              key={`article-${index}`}
              title={node.frontmatter.title}
              uri={node.fields.uri}
              date={node.fields.date}
              excerpt={node.frontmatter.excerpt !== undefined && node.frontmatter.excerpt && node.frontmatter.excerpt.length > 0 ? node.frontmatter.excerpt : node.excerpt}
              coverPicture={node.frontmatter.coverPicture}
            />
          ))}
          <Link to="/posts" className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 md:py-4 md:text-lg md:px-10 no-underline dark:text-white">View All Posts</Link>
        </div>
      </section>
    </Layout>
  );
}

IndexPage.propTypes = {
  data: PropTypes.object
};

export default IndexPage;

export const query = graphql`
  query {
    posts: allMarkdownRemark(
      filter: { fields: {slug: { ne: null }}, frontmatter: {draft: {ne: true}} }
      sort: { fields: [fields___prefix], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            date: prefix(formatString: "MMMM DD, YYYY")
            uri
          }
          frontmatter {
            title
            excerpt
            coverPicture {
              childImageSharp {
                gatsbyImageData(width: 566)
              }
            }
          }
        }
      }
    }

    DativeLogo: file(relativePath: {eq: "dative-logo.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 64
          height: 64
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }

    RodrigoHeadshot: file(relativePath: {eq: "rodrigo-dative-square.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 180
          height: 180
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
